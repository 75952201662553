<template>
  <v-card>
    <v-card-title class="justify-center flex-column">
      <span class="mb-2">{{ userdata.full_name }}</span>

      <v-chip
        label
        small
        color="info"
        :class="`v-chip-light-bg text-sm font-weight-semibold info--text`"
      >
        {{ $t(getRole(userdata.role)) }}
      </v-chip>

      <vue-qr
        :text="JSON.stringify({id: userdata.id_user, phone: userdata.phone})"
        :size="150"
      ></vue-qr>
    </v-card-title>

    <v-card-text>
      <h2 class="text-xl font-weight-semibold mb-2">
        {{ $t('Details') }}
      </h2>

      <v-divider></v-divider>

      <v-list>
        <v-list-item
          dense
          class="px-0 mb-n2"
        >
          <span class="font-weight-medium text-no-wrap me-2">{{ $t('PhoneNumber') }}:</span>
          <span class="text--secondary">{{ userdata.phone }}</span>
          <span>
            <v-btn
              icon
              @click="showPhoneNumberEditDialog"
            >
              <v-icon small>
                {{ icons.mdiPencilOutline }}
              </v-icon>
            </v-btn>
          </span>
        </v-list-item>

        <v-list-item
          dense
          class="px-0 mb-n2"
        >
          <span class="font-weight-medium me-2">{{ $t('Status') }} :</span>
          <span class="text--secondary">
            <v-chip
              v-if="!userdata.is_lock"
              small
              label
              color="primary"
              class="v-chip-light-bg primary--text font-weight-medium text-capitalize"
            >
              {{ $t('Active') }}
            </v-chip>
            <v-chip
              v-else
              small
              label
              color="error"
              class="`v-chip-light-bg primary--text font-weight-medium text-capitalize`"
            >
              {{ $t('Inactive') }}
            </v-chip>
          </span>
        </v-list-item>

        <v-list-item
          dense
          class="px-0 mb-n2"
        >
          <span class="font-weight-medium me-2">{{ $t('Gender') }}:</span>
          <span class="text--secondary text-capitalize">{{ $t(getGender(userdata.gender)) }}</span>
        </v-list-item>
        <v-list-item
          dense
          class="px-0 mb-n2"
        >
          <span class="font-weight-medium me-2">{{ $t('YearOfBirth') }}:</span>
          <span class="text--secondary">{{ userdata.year_of_birth }}</span>
        </v-list-item>
      </v-list>
    </v-card-text>

    <v-card-text>
      <v-row justify="space-around">
        <v-col
          cols="6"
          md="3"
          class="text-center"
        >
          <v-avatar size="130">
            <v-img
              :src="userdata.face_image_url"
              aspect-ratio="1"
            />
          </v-avatar>
          <div>
            {{ $t('FaceImage') }}
          </div>
        </v-col>
        <v-col
          cols="6"
          md="3"
          class="text-center"
        >
          <v-avatar size="130">
            <v-img
              :src="userdata.face_mask_image_url"
              aspect-ratio="1"
            />
          </v-avatar>
          <div>
            {{ $t('FaceWithMaskImage') }}
          </div>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-text>
      <v-row class="mt-1">
        <v-col
          cols="12"
          sm="6"
        >
          <v-btn
            outlined
            color="error"
            block
            @click="showPasswordUpdateDialog"
          >
            {{ $t('UpdatePassword') }}
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-btn
            outlined
            color="success"
            block
            @click="$router.push({ name: 'user-update-information' })"
          >
            {{ $t('UpdateInfo') }}
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-btn
            outlined
            color="primary"
            block
            @click="$router.push({ name: 'user-family' })"
          >
            {{ $t('YourFamily') }}
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-btn
            outlined
            color="primary"
            block
            @click="$router.push({ name: 'user-activity' })"
          >
            {{ $t('Activity') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>

    <user-phone-number-edit-dialog
      :key="`phone-number-${phoneNumberDialogKey}`"
      :is-phone-number-dialog-open.sync="isPhoneNumberDialogOpen"
      @handle-update-phone-number="handleUpdatePhoneNumber"
    ></user-phone-number-edit-dialog>

    <user-password-update-dialog
      :key="`password-${passwordDialogKey}`"
      :is-password-dialog-open.sync="isPasswordDialogOpen"
    ></user-password-update-dialog>
  </v-card>
</template>

<script>
import { mdiPencilOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import UserPhoneNumberEditDialog from './components/UserPhoneNumberEditDialog.vue'
import UserPasswordUpdateDialog from './components/UserPasswordUpdateDialog.vue'
import VueQr from 'vue-qr'
import store from '@/store'
import { getRole, getGender } from '@/utils/accountHelper'

export default {
  components: {
    UserPhoneNumberEditDialog,
    UserPasswordUpdateDialog,
    VueQr,
  },
  setup() {
    const phoneNumberDialogKey = ref(0)
    const passwordDialogKey = ref(0)
    const isPhoneNumberDialogOpen = ref(false)
    const isPasswordDialogOpen = ref(false)
    const userdata = ref({ ...store.getters['auth/currentUser'] })

    const showPhoneNumberEditDialog = () => {
      isPhoneNumberDialogOpen.value = !isPhoneNumberDialogOpen.value
      phoneNumberDialogKey.value += 1
    }

    const showPasswordUpdateDialog = () => {
      isPasswordDialogOpen.value = !isPasswordDialogOpen.value
      phoneNumberDialogKey.value += 1
    }

    const handleUpdatePhoneNumber = () => {
      userdata.value = { ...store.getters['auth/currentUser'] }
    }

    return {
      userdata,
      phoneNumberDialogKey,
      passwordDialogKey,
      isPhoneNumberDialogOpen,
      isPasswordDialogOpen,
      showPhoneNumberEditDialog,
      showPasswordUpdateDialog,
      handleUpdatePhoneNumber,
      getRole,
      getGender,
      icons: {
        mdiPencilOutline,
      },
    }
  },
}
</script>
