<template>
  <v-dialog
    v-model="isPasswordDialogOpen"
    max-width="650px"
    persistent
  >
    <v-card>
      <v-card-title>
        {{ $t('UpdatePassword') }}
      </v-card-title>

      <v-card-text>
        <v-form
          ref="updatePasswordForm"
          @submit.prevent="handeSubmitForm"
        >
          <v-text-field
            v-model="currentPassword"
            dense
            outlined
            :type="isCurrentPasswordVisible ? 'text' : 'password'"
            :label="$t('CurrentPassword')"
            :placeholder="$t('CurrentPassword')"
            :append-icon="isCurrentPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
            hide-details="auto"
            class="mb-4"
            :rules="[validators.required, validators.passwordValidator]"
            validate-on-blur
            @click:append="isCurrentPasswordVisible = !isCurrentPasswordVisible"
          ></v-text-field>
          <v-text-field
            v-model="newPassword"
            dense
            outlined
            :type="isNewPasswordVisible ? 'text' : 'password'"
            :label="$t('NewPassword')"
            :placeholder="$t('NewPassword')"
            :append-icon="isNewPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
            hide-details="auto"
            class="mb-4"
            :rules="[validators.required, validators.passwordValidator]"
            validate-on-blur
            @click:append="isNewPasswordVisible = !isNewPasswordVisible"
          ></v-text-field>
          <v-text-field
            v-model="confirmPassword"
            dense
            outlined
            :type="isConfirmPasswordVisible ? 'text' : 'password'"
            :label="$t('ConfirmPassword')"
            :placeholder="$t('ConfirmPassword')"
            :append-icon="isConfirmPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
            hide-details="auto"
            class="mb-4"
            :rules="[
              validators.required, validators.confirmPasswordValidator(newPassword, confirmPassword)
            ]"
            validate-on-blur
            @click:append="isConfirmPasswordVisible = !isConfirmPasswordVisible"
          ></v-text-field>

          <v-row>
            <v-col>
              <v-btn
                block
                color="primary"
                type="submit"
                :loading="loading"
              >
                {{ $t('Submit') }}
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                outlined
                block
                @click="$emit('update:is-password-dialog-open',false)"
              >
                {{ $t('Cancel') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiEyeOutline, mdiEyeOffOutline, mdiReload } from '@mdi/js'
import { required, passwordValidator, confirmPasswordValidator } from '@core/utils/validation'
import { ref } from '@vue/composition-api'
import store from '@/store'
import AccountService from '@/services/AccountService'

export default {
  props: {
    isPasswordDialogOpen: {
      type: Boolean,
      required: true,
    },
  },
  setup(_, { emit }) {
    // Template ref
    const updatePasswordForm = ref(null)

    const isCurrentPasswordVisible = ref(false)
    const isNewPasswordVisible = ref(false)
    const isConfirmPasswordVisible = ref(false)
    const currentPassword = ref('')
    const newPassword = ref('')
    const confirmPassword = ref('')
    const loading = ref(false)

    const handeSubmitForm = async () => {
      const isFormValid = updatePasswordForm.value.validate()

      if (!isFormValid) return
      loading.value = true
      try {
        await AccountService.changePassword({
          current_password: currentPassword.value,
          password: newPassword.value,
        })

        store.commit('snackbar/show', {
          message: 'Update Password Successfully',
          type: 'success',
        })

        emit('update:is-password-dialog-open', false)
      } catch {
      } finally {
        loading.value = false
      }
    }

    const handleBackToProfile = () => {
      emit('update:is-password-dialog-open', false)
    }

    return {
      loading,
      isCurrentPasswordVisible,
      isNewPasswordVisible,
      isConfirmPasswordVisible,
      currentPassword,
      newPassword,
      confirmPassword,

      handeSubmitForm,
      handleBackToProfile,

      validators: {
        required,
        passwordValidator,
        confirmPasswordValidator,
      },

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiReload,
      },

      // Template Refs
      updatePasswordForm,
    }
  },
}
</script>
