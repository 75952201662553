<template>
  <v-dialog
    v-model="isPhoneNumberDialogOpen"
    max-width="650px"
    persistent
  >
    <v-card>
      <v-card-title>
        {{ $t('UpdatePhoneNumber') }}
      </v-card-title>

      <v-card-text v-if="step===0">
        <v-form
          ref="updatePhoneNumberForm"
          @submit.prevent="handleSubmitPhoneNumberForm"
        >
          <v-text-field
            v-model="password"
            dense
            outlined
            :type="isPasswordVisible ? 'text' : 'password'"
            :label="$t('Password')"
            :placeholder="$t('Password')"
            :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
            hide-details="auto"
            class="mb-6"
            :rules="[validators.required, validators.passwordValidator]"
            validate-on-blur
            @click:append="isPasswordVisible = !isPasswordVisible"
          ></v-text-field>
          <v-text-field
            v-model="phoneNumber"
            dense
            outlined
            type="tel"
            :label="$t('NewPhoneNumber')"
            :placeholder="$t('NewPhoneNumber')"
            :rules="[validators.required, validators.phoneNumberValidator]"
            hide-details="auto"
            class="mb-6"
            validate-on-blur
          >
          </v-text-field>

          <v-row>
            <v-col>
              <v-btn
                block
                color="primary"
                type="submit"
                :loading="loadingPhoneNumberForm"
              >
                {{ $t('Submit') }}
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                outlined
                block
                @click="$emit('update:is-phone-number-dialog-open',false)"
              >
                {{ $t('Cancel') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-text v-if="step===1">
        <p class="mb-6">
          {{ $t('ConfirmOTPSubtitle', { phoneNumber: phoneNumber }) }}
        </p>
        <v-form
          ref="confirmOTPForm"
          @submit.prevent="handleSubmitOTP"
        >
          <v-text-field
            v-model="otp"
            dense
            outlined
            :label="$t('EnterOTP')"
            :placeholder="$t('EnterOTP')"
            :rules="[validators.required]"
            hide-details="auto"
            class="mb-3"
            validate-on-blur
          >
            <template
              v-slot:append
            >
              <i18n
                v-if="time"
                path="ResendAfterTime"
                class="mt-1"
              >
                <template #time>
                  {{ time }}
                </template>
              </i18n>
              <a
                v-else
                class="mt-1"
                @click="handleGetOtp"
              >{{ $t('Resend') }}
              </a>
            </template>
          </v-text-field>

          <v-row>
            <v-col>
              <v-btn
                block
                color="primary"
                type="submit"
                :loading="isCheckingOTP"
              >
                {{ $t('Submit') }}
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                block
                outlined
                @click="step--"
              >
                {{ $t('Back') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-text v-if="step===2">
        <p class="mb-6">
          {{ $t('UpdatePhoneNumberSuccessMsg' ) }}
        </p>

        <v-row>
          <v-col>
            <v-btn
              block
              color="primary"
              @click="handleBackToProfile"
            >
              {{ $t('BackToProfile') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiEyeOutline, mdiEyeOffOutline, mdiReload } from '@mdi/js'
import { required, passwordValidator, phoneNumberValidator } from '@core/utils/validation'
import { ref, watch } from '@vue/composition-api'
import AccountService from '@/services/AccountService'
import store from '@/store'
import { useIntervalFn } from '@vueuse/core'

const TIMER = 60

export default {
  props: {
    isPhoneNumberDialogOpen: {
      type: Boolean,
      required: true,
    },
  },
  setup(_, { emit }) {
    const userdata = ref({ ...store.getters['auth/currentUser'] })

    // Template ref
    const updatePhoneNumberForm = ref(null)
    const confirmOTPForm = ref(null)

    const isPasswordVisible = ref(false)
    const step = ref(0)
    const password = ref('')
    const phoneNumber = ref('')
    const otp = ref('')

    const loadingPhoneNumberForm = ref(false)
    const isCheckingOTP = ref(false)

    const time = ref(0)

    const { resume: startTimer, pause: pauseTimer } = useIntervalFn(
      () => {
        time.value -= 1
      },
      1000,
      false,
    )

    const resetTimer = () => {
      time.value = TIMER
      startTimer()
    }

    watch(time, value => {
      if (value === 0) {
        pauseTimer()
      }
    })

    const handleGetOtp = async (isResetTimer = true) => {
      try {
        const otpResp = await AccountService.getOTP({
          phone: phoneNumber.value,
          action_otp: 'update_phone',
        })

        if (isResetTimer) {
          resetTimer()
        }

        // ! Hardcode OTP
        otp.value = otpResp.data.data.otp_debug
      } catch (error) {}
    }

    const handleSubmitPhoneNumberForm = async () => {
      const isFormValid = updatePhoneNumberForm.value.validate()

      if (!isFormValid) return
      loadingPhoneNumberForm.value = true
      try {
        const passwordCheckingRes = await AccountService.checkMyPassword({
          password: password.value,
        })
        const isPasswordCorrect = passwordCheckingRes.data.data.result

        if (!isPasswordCorrect) {
          store.commit('snackbar/show', {
            message: 'Wrong password',
            type: 'error',
          })

          return
        }

        const phoneCheckingResp = await AccountService.checkPhoneNumber({
          phone: phoneNumber.value,
        })
        const isPhoneNumberExisted = phoneCheckingResp.data.data.result

        if (isPhoneNumberExisted) {
          store.commit('snackbar/show', {
            message: 'This phone number has already existed',
            type: 'error',
          })

          return
        }

        handleGetOtp(false)

        step.value += 1
      } catch {
      } finally {
        loadingPhoneNumberForm.value = false
      }
    }

    const handleSubmitOTP = async () => {
      const isFormValid = confirmOTPForm.value.validate()

      if (!isFormValid) return
      isCheckingOTP.value = true
      try {
        await AccountService.checkOTP({
          phone: phoneNumber.value,
          otp_code: otp.value,
        })

        await AccountService.changePhoneNumberWithOTP({
          password: password.value,
          otp: otp.value,
          phone: phoneNumber.value,
          id_user: userdata.id_user,
        })

        store.dispatch('auth/getUserInfo')

        step.value += 1
      } catch {
      } finally {
        isCheckingOTP.value = false
      }
    }

    const handleBackToProfile = () => {
      emit('handle-update-phone-number')
      emit('update:is-phone-number-dialog-open', false)
    }

    return {
      step,
      time,
      isPasswordVisible,
      password,
      phoneNumber,
      otp,

      handleSubmitPhoneNumberForm,
      handleSubmitOTP,
      handleBackToProfile,
      handleGetOtp,

      loadingPhoneNumberForm,
      isCheckingOTP,

      validators: {
        required,
        passwordValidator,
        phoneNumberValidator,
      },

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiReload,
      },

      // Template Refs
      updatePhoneNumberForm,
      confirmOTPForm,
    }
  },
}
</script>
